import { $, addAction, INIT } from '@situation/setdesign.util';

addAction(INIT, () => {
    if (!window.Plyr) {
        return;
    }

    const players = [];
    $('audio').each((_, el) => {
        const player = new window.Plyr(el);
        players.push(player);
        player.on('play', () => {
            players.forEach((p) => {
                if (p !== player) {
                    p.pause();
                }
            });
        });
    });
});

addAction('toggleActive', () => {
    $('.toggle-active .owl-carousel').trigger('refresh.owl.carousel');
});
